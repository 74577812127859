<template>
    <div class="my-account max-w-xl mx-auto mb-10">
        <h1 class="text-center">{{ $t('navigation.usermenu.account') }}</h1>
        <div class="content-container checkout-box radius-anchor wallets">
            <h3 class="text-xl">{{ $t('mywallet.title') }}</h3>
            <div v-if="user && user.hasWallets">
                <div v-for="(wallet, wallet_index) in wallets.slice(0,2)" :key="wallet_index"
                     class="wallets-container"
                >
                    <h2>
                        {{ wallet.attributes.description }}
                    </h2>

                    <div class="wallets">
                        <div class="wallet radius-anchor" v-for="(balance, balance_index) in wallet.attributes.balances"
                             :key="balance_index"
                        >
                            <h3>{{ balance.type }}: {{ balance.balance }}</h3>
                            <!--                        <modal-wallet-mutations :balance="balance.balance" :type="balance.type"-->
                            <!--                                                :wallet="wallet.attributes.description" />-->
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                {{ $t('mywallet.no-wallet') }}
            </div>

            <router-link v-if="user && user.hasWallets === true" :to="{ name: 'user.myaccount.wallet' }"
                         class="flex justify-end pt-4"
            >
                <a>{{ $t('mywallet.all') }}</a>
            </router-link>
        </div>

        <div class="content-container checkout-box radius-anchor orders">
            <h3 class="text-xl">{{ $t('navigation.usermenu.mytransactions') }}</h3>
                <user-transactions :short-list="true" v-if="user" />
                <router-link :to="{ name: 'user.myaccount.transactions' }" class="flex justify-end pt-4 view-orders">
                    <a>{{ $t('mytransactions.view') }}</a>
                </router-link>
        </div>

        <div class="content-container checkout-box radius-anchor" v-if="customer">
            <h3 class="text-xl">{{ $t('navigation.usermenu.myprofile') }}</h3>

            <div>{{customer.attributes.firstname}} {{ customer.attributes.lastname }}</div>
            <div>{{customer.attributes.streetname}} {{ customer.attributes.housenumber }}</div>
            <div>{{customer.attributes.zipcode}} {{ customer.attributes.city }}</div>
            <div>{{customer.attributes.email}} </div>
            <div>{{customer.attributes.phone_number}} </div>

            <router-link :to="{ name: 'user.myaccount.profile' }" class="flex justify-end pt-4">
                <a>{{ $t('navigation.usermenu.myprofile.edit') }}</a>
            </router-link>
        </div>

        <div class="content-container checkout-box radius-anchor">
            <h3 class="text-xl">{{ $t('auth.passwordreset.update.password') }}</h3>
            <router-link :to="{ name: 'user.myaccount.update-password' }" class="py-2 text-sm">
                <button class="btn btn-primary">{{ $t('auth.passwordreset.update.password') }}</button>
            </router-link>
        </div>
    </div>
</template>

<script>
import * as _api from "../lib/api";
import * as _state from "../lib/state";

import UserTransactions from "./UserTransactions";

export default {
    name: 'user.myaccount',
    components: { UserTransactions },
    data() {
        return {
            customer: null,
            wallets: [],
        }
    },
    mounted () {
        this.getCustomer();

        if (this.user.hasWallets === true) {
            this.customerWallets();
        }

        if (this.wallets && this.wallets.length > 2) {
            this.wallets = this.wallets.slice(0, 2);
        }
    },

    methods: {
        async customerWallets () {
            this.wallets = await _api.get('customer/wallets', {}, false);
        },

        async getCustomer() {
            //! needs testing
            this.customer = await _api.get('customer', {}, false);

            if (!this.customer) {
                this.$router.push({name: 'landing'});
            }
        },
    },
    computed: {
        user () {
            return _state.get('user/getUser');
        },
    },
};
</script>
